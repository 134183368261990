@import '../abstracts/variables';

$settings-header-height: 60px;

.form-grid {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;

  &__space-between {
    display: grid;
    grid-template-columns: 1fr minmax(0, auto);
    align-items: center;
    grid-gap: 16px;
  }

  &__two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    @media #{$large-down} {
      grid-template-columns: auto;
      grid-template-rows: 1fr 1fr;
    }

    &.flex {
      display: flex;

      @media #{$large-down} {
        flex-direction: column;
      }
    }
  }

  &__three-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;

    @media #{$large-down} {
      grid-template-columns: auto;
      grid-template-rows: 1fr 1fr 1fr;
    }

    &.flex {
      display: flex;

      @media #{$large-down} {
        flex-direction: column;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 16px;
    position: sticky;
    bottom: 0;
    background-color: var(--white);
    padding-top: 12px;
    padding-bottom: 12px;

    &--flex-end {
      display: flex;
      justify-content: flex-end;
      grid-gap: 16px;
    }

    &.stuck {
      box-shadow: 1px 1px 10px 0 rgb(11 28 50 / 15%);
      margin-left: -32px;
      margin-right: -32px;
      padding-left: 32px;
      padding-right: 32px;

      @media #{$large-down} {
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}

.list-item {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  align-items: center;
  gap: 16px;
  background-color: var(--gray-100);
  border-radius: 4px;
  border: 1px solid var(--gray-300);
  padding: 16px 24px;
  cursor: pointer;

  $current: &;

  &__prefix {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border: 1px solid var(--gray-300);
    border-radius: 50%;

    i {
      font-size: rem-calc(18);
    }
  }

  &:hover {
    border-color: var(--gray-325);

    #{$current}__prefix {
      i {
        color: var(--primary);
      }
    }
  }
}


.settings-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  &__return-link {
    font-size: rem-calc(14);
    font-weight: 600;
    text-decoration: none;
    margin-bottom: 8px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media #{$large-down} {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.new-settings-card {
  padding: 24px 32px;
  background-color: var(--white);
  border: none;

  @media #{$large-down} {
    margin: 0;
    padding: 24px 16px;
  }

  @media #{$medium-up} {
    border-radius: 4px;
  }
}

.settings-card-header {
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid var(--gray-300);
  margin: 0 -32px 24px;
  padding: 0 32px 24px;

  @media #{$large-down} {
    margin: 0 -16px 24px;
    padding: 0 16px 24px;
  }

  &.small-margin-bottom {
    margin-bottom: 16px;
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }

  &.no-border-bottom {
    border-bottom: none;
  }

  &__prefix-circle {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 1px solid var(--gray-300);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: rem-calc(32);
    color: var(--primary);
  }

  &__prefix {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: rem-calc(32);
    color: var(--primary);
  }

  &__content {
    width: 100%;
  }

  &__pills {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    grid-gap: 4px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  &__list-cta {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    width: 100%;

    @media #{$large-down} {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    &--single-cta {
      justify-content: flex-end;
    }

  }
}

.settings-card-filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.settings-tabs-menu {
  margin: 0 -32px;
  width: unset;

  @media #{$large-down} {
    margin: 0 -16px;
  }

  .scroll-container {
    width: calc(100% - 64px);
    margin: 0 32px;
  }
}

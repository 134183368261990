@import '../abstracts';

.invoices-body {
  width: auto;
  max-width: $body-width;
  margin: 24px 60px;

  &.small {
    max-width: 700px;
  }

  &.no-padding-top {
    margin: 0 60px 24px;
  }

  &.auto-margin {
    margin: auto;
  }
}

.invoices-header {
  width: 100%;
  background-color: var(--white);
}